import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import { useNavigate } from 'react-router-dom';
import LeftMenu from '../components/LeftMenu';
import { getFollowers } from '../services/feed';
import { Spinner } from 'react-bootstrap';
import i18n from '../utils/i18n';
// import newUseFeed from '../hooks/newUseFeed';
// import Suggestions from '../components/Suggestions';

type Data = {
  id: string;
  userId: string;
  avatar: string;
  username: string;
};

const Following = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<Data[]>([]);

  // const { loadSuggestions } = newUseFeed();

  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      try {
        const items = await getFollowers();
        setItems(items);

        // if (items.length === 0) {
        // await loadSuggestions();
        // }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    load();
  }, []);

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            {loading ? (
              <div
                className="align-self-center d-flex flex-column justify-content-center align-items-center"
                style={{
                  padding: 100,
                }}
              >
                <Spinner
                  animation="border"
                  role="status"
                  className="text-warning"
                />
              </div>
            ) : (
              <div className="row">
                <div className="col-xl-12">
                  <div className="card-body bg-white border-0 d-flex rounded-3">
                    <h4 className="font-xs text-black fw-600 mb-0 mt-2">
                      {i18n.t<string>('Following.title', {
                        number: items.length,
                      })}
                    </h4>
                  </div>
                  <div className="card-body bg-white">
                    <div className="mb-5 d-flex flex-wrap justify-content-center">
                      {items.length ? (
                        items.map((value, index) => (
                          <div
                            onClick={() => navigate(`/${value.username}`)}
                            key={index}
                            className="p-2"
                            style={{
                              cursor: 'pointer',
                              width: 150,
                              height: 150,
                            }}
                          >
                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                              <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                                <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
                                  <img
                                    src={
                                      value.avatar
                                        ? value.avatar
                                        : 'assets/images/user-100x100.png'
                                    }
                                    alt="avater"
                                    className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                                  />
                                </figure>
                                <div className="clearfix w-100"></div>
                                <h4
                                  className="fw-700 font-xsss mt-3 mb-0"
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {value.username}
                                </h4>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="align-self-center d-flex flex-column justify-content-center align-items-center p-5">
                          <img
                            src="assets/images/logo-blank.png"
                            alt="avater"
                            style={{
                              width: 50,
                              height: 50,
                            }}
                          />
                          <span className="text-grey-600 m-2 text-center font-xss">
                            {i18n.t<string>('Following.noFollowing')}
                          </span>
                        </div>
                      )}
                    </div>
                    {/* <div className="col-xl-4 suggestions mt-2">
                      <Suggestions suggestionList={suggestions} />
                    </div> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Appfooter />
    </>
  );
};

export default Following;
