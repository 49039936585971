enum SubscriptionStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  PROCESSING = 'PROCESSING',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  NONE = 'NONE',
}

export default SubscriptionStatus;
