import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { myProfile } from '../signals/profile';
import { effect } from '@preact/signals';
import { scrolling } from '../signals/feedScrollPage';
import { isMobile } from '../utils/data';
import { hasActivePlan, isCreator } from '../signals/creator';
import { getSessionObject, setSessionObject } from '../utils/storage';

const Header: React.FC = () => {
  const [isScrollingPage, setIsScrollingPage] = useState(false);
  const [showBannerMessage, setShowBannerMessage] = useState(false);

  effect(() => {
    if (!isMobile()) return;

    if (!isScrollingPage && scrolling.value) {
      setIsScrollingPage(true);
    } else if (isScrollingPage && !scrolling.value) {
      setIsScrollingPage(false);
    }
  });

  useEffect(() => {
    const hasAlreadyClosedBanner = getSessionObject('hasAlreadyClosedBanner');
    if (!hasAlreadyClosedBanner && isCreator.value && !hasActivePlan.value) {
      setShowBannerMessage(true);
    }
  }, []);

  return (
    <>
      <div
        className="nav-header shadow-xs border-0"
        style={{ visibility: isScrollingPage ? 'hidden' : 'visible' }}
      >
        <div className="nav-top d-flex justify-content-between">
          <Link to={isCreator.value ? '/myprofile' : '/'}>
            <img
              src="assets/images/logo.png"
              style={{
                maxHeight: 145,
                maxWidth: 145,
              }}
              alt=""
            />
          </Link>
        </div>
        <span
          className="p-2 pointer text-center ms-auto menu-icon"
          id="dropdownMenu3"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        />
        <Link to="/menu" className="p-0 ms-3 menu-icon">
          <figure className="avatar">
            {myProfile.value.profile.avatar ? (
              <img
                src={myProfile.value.profile.avatar}
                alt="avater"
                className="float-right p-1 bg-white rounded-circle w60"
              />
            ) : (
              <i className="feather-user font-xl text-warning" />
            )}
          </figure>
        </Link>
      </div>
      {showBannerMessage && (
        <div
          className="alert d-flex justify-content-between align-items-center"
          style={{
            marginBottom: 0,
            padding: '10px 20px',
            fontSize: '14px',
            position: 'fixed',
            zIndex: 999,
            right: 0,
            left: 0,
            top: 0,
            marginTop: 75,
            backgroundColor: '#fff8e1', // Softer yellow background
            color: '#856404', // Darker text for better readability
            border: '1px solid #ffeeba', // Border to match the background
          }}
        >
          <span>
            Olá, agora que você é um criador,{' '}
            <Link
              to="/creator-settings"
              className="fw-600"
              onClick={() => {
                setShowBannerMessage(false);
                setSessionObject('hasAlreadyClosedBanner', true);
              }}
              style={{
                textDecoration: 'underline',
                color: '#856404', // Match the text color for consistency
              }}
            >
              cadastre o seu plano mensal aqui.
            </Link>
          </span>
          <button
            className="btn-close"
            onClick={() => {
              setShowBannerMessage(false);
              setSessionObject('hasAlreadyClosedBanner', true);
            }}
            style={{
              background: 'none',
              border: 'none',
              fontSize: '16px',
              cursor: 'pointer',
              color: '#856404', // Match the close button color
            }}
          >
            <i className="feather-x" />
          </button>
        </div>
      )}
    </>
  );
};

export default Header;
