import React, { useCallback, useEffect, useState } from 'react';
import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import ProfileDetail from '../components/ProfileDetail';
import ProfileCard from '../components/ProfileCard';
import { useNavigate, useLocation } from 'react-router-dom';
import { session } from '../signals/session';
import NewFeedScrollPage from '../components/Utility/NewFeedScrollPage';
import NewFeed from '../components/Utility/NewFeed';
import LeftMenu from '../components/LeftMenu';
import newUseFeed from '../hooks/newUseFeed';
import SubscriptionModal from '../modals/SubscriptionModal';
import SubscriptionStatus from '../enums/SubscriptionStatus';

const UserPage = () => {
  const navigate = useNavigate();

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const [username, setUsername] = useState('');

  const location = useLocation();

  const {
    feed,
    plan,
    isFeedLoading,
    isFeedNextPageLoading,
    loadPage,
    onLikePost,
    fetchFeedNextPage,
  } = newUseFeed();

  useEffect(() => {
    const username = location.pathname.replace('/', '');
    if (username === session.value.username) {
      navigate('/myprofile');
      return;
    }
    setUsername(username);
    loadPage(username);
  }, [loadPage, location.pathname, navigate]);

  const onReachOutBottom = () => {
    const username = location.pathname.replace('/', '');
    fetchFeedNextPage(username);
  };

  const onSubscribe = useCallback(async () => {
    setShowSubscriptionModal(true);
  }, []);

  return (
    <div>
      <Header />
      <LeftMenu />
      <NewFeedScrollPage
        loading={isFeedLoading}
        footerLoading={isFeedNextPageLoading}
        onReachOutBottom={onReachOutBottom}
        feed={feed}
      >
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12 mb-3">
                <ProfileCard
                  username={username}
                  metadata={feed.metadata}
                  onSubscribe={onSubscribe}
                  plan={plan}
                />
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                <ProfileDetail profile={feed.metadata.profile} />
              </div>
              <div className="col-xl-8 col-xxl-9 col-lg-8 mb-5">
                <div className="card w-100 shadow-xss rounded-xxl border-0 d-flx flex-row justify-content-between align-items-center p-3 mb-3">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <i className="font-sm feather-lock mb-2 text-grey-500"></i>
                    <h4 className="fw-600 text-grey-600 font-xsss mb-0 mt-0">
                      {`${feed.metadata.totalPosts} ${
                        feed.metadata.totalPosts === 1
                          ? 'publicação'
                          : 'publicações'
                      }`}
                    </h4>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <i className="font-sm feather-image mb-2 text-grey-500"></i>
                    <h4 className="fw-600 text-grey-600 font-xsss mb-0 mt-0">
                      {`${feed.metadata.totalImages} ${
                        feed.metadata.totalImages === 1 ? 'foto' : 'fotos'
                      }`}
                    </h4>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <i className="font-sm feather-film mb-2 text-grey-500"></i>
                    <h4 className="fw-600 text-grey-600 font-xsss mb-0 mt-0">
                      {`${feed.metadata.totalVideos} ${
                        feed.metadata.totalVideos === 1 ? 'vídeo' : 'vídeos'
                      }`}
                    </h4>
                  </div>
                </div>
                <NewFeed
                  isNextPageLoading={isFeedNextPageLoading}
                  feed={feed}
                  onLikePost={onLikePost}
                />
                {feed.metadata.subscribed === SubscriptionStatus.NONE && feed.metadata.totalPosts > 0 && (
                  <div className="card w-100 shadow-xss rounded-xxl border-0 p-3 mb-3 bg-warning">
                    <div className="card-body p-0 d-flex flex-column justify-content-center align-items-center">
                      <img
                        src="assets/images/logo-only.png"
                        width={100}
                        alt=""
                        style={{ opacity: 0.8 }}
                      />
                      <p className="font-xsss fw-600 text-white mt-4 text-center">
                        {`Tenha acesso a mais conteúdo exclusivo por apenas R$ ${
                          plan.value / 100
                        }
                      por mês`}
                      </p>
                      <div className="card w-100 shadow-xss rounded-xxl border-0 d-flx flex-row justify-content-between align-items-center p-3 m-3">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <i className="font-sm feather-lock mb-2 text-grey-500"></i>
                          <h4 className="fw-600 text-grey-600 font-xsss mb-0 mt-0">
                            {`${feed.metadata.totalPosts} ${
                              feed.metadata.totalPosts === 1
                                ? 'publicação'
                                : 'publicações'
                            }`}
                          </h4>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <i className="font-sm feather-image mb-2 text-grey-500"></i>
                          <h4 className="fw-600 text-grey-600 font-xsss mb-0 mt-0">
                            {`${feed.metadata.totalImages} ${
                              feed.metadata.totalImages === 1 ? 'foto' : 'fotos'
                            }`}
                          </h4>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <i className="font-sm feather-film mb-2 text-grey-500"></i>
                          <h4 className="fw-600 text-grey-600 font-xsss mb-0 mt-0">
                            {`${feed.metadata.totalVideos} ${
                              feed.metadata.totalVideos === 1
                                ? 'vídeo'
                                : 'vídeos'
                            }`}
                          </h4>
                        </div>
                      </div>

                      <button
                        onClick={() => onSubscribe()}
                        className="btn p-3 mt-4 rounded-md bg-warning-600 border font-xss fw-700 text-white profile-card-buttons-subscriptions-buttons w-100 border-2"
                      >
                        Assinar agora
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </NewFeedScrollPage>
      <SubscriptionModal
        isVisible={showSubscriptionModal}
        onClose={() => {
          setShowSubscriptionModal(false);
          window.location.reload();
        }}
        plan={plan}
      />
      <Appfooter />
    </div>
  );
};

export default UserPage;
