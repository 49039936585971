/**
 * Validates a CPF (Brazilian individual taxpayer registry) number
 * @param cpf CPF number (can include formatting characters)
 * @returns true if CPF is valid, false otherwise
 */
export function validateCPF(cpf: string): boolean {
  // Remove non-numeric characters
  const cleanCPF = cpf.replace(/\D/g, '');

  // Check if it has 11 digits and if it's not a sequence of the same digit
  if (cleanCPF.length !== 11 || /^(\d)\1{10}$/.test(cleanCPF)) {
    return false;
  }

  // Calculate first verification digit
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
  }
  let remainder = sum % 11;
  const digit1 = remainder < 2 ? 0 : 11 - remainder;

  // Calculate second verification digit
  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
  }
  remainder = sum % 11;
  const digit2 = remainder < 2 ? 0 : 11 - remainder;

  // Check if calculated verification digits match the provided ones
  return (
    digit1 === parseInt(cleanCPF.charAt(9)) &&
    digit2 === parseInt(cleanCPF.charAt(10))
  );
}

export function validateCNPJ(cnpj: string): boolean {
  cnpj = cnpj.replace(/\D/g, ''); // Remove non-numeric characters
  if (cnpj.length !== 14) return false;

  let length = cnpj.length - 2;
  let numbers = cnpj.substring(0, length);
  let digits = cnpj.substring(length);
  let sum = 0;
  let pos = length - 7;

  for (let i = length; i >= 1; i--) {
    sum += parseInt(numbers.charAt(length - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(0))) return false;

  length = length + 1;
  numbers = cnpj.substring(0, length);
  sum = 0;
  pos = length - 7;

  for (let i = length; i >= 1; i--) {
    sum += parseInt(numbers.charAt(length - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  return result === parseInt(digits.charAt(1));
}

/**
 * Validates a date in DD/MM/YYYY format
 * @param date Date string in DD/MM/YYYY format
 * @returns true if date is valid, false otherwise
 */
export function validateDate(date: string): boolean {
  // Check format
  if (!/^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
    return false;
  }

  // Parse date parts
  const parts = date.split('/');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // JS months are 0-based
  const year = parseInt(parts[2], 10);

  // Create date object and verify
  const dateObj = new Date(year, month, day);
  
  // Check if the date is valid and matches the input
  return (
    dateObj.getFullYear() === year &&
    dateObj.getMonth() === month &&
    dateObj.getDate() === day
  );
}

/**
 * Checks if a person is at least a certain age based on their birthdate
 * @param birthdate Date string in DD/MM/YYYY format
 * @param minAge Minimum age (default is 18)
 * @returns true if person is at least minAge years old, false otherwise
 */
export function isMinimumAge(birthdate: string, minAge: number = 18): boolean {
  if (!validateDate(birthdate)) {
    return false;
  }

  // Parse the birth date
  const parts = birthdate.split('/');
  const birthDay = parseInt(parts[0], 10);
  const birthMonth = parseInt(parts[1], 10) - 1; // JS months are 0-based
  const birthYear = parseInt(parts[2], 10);
  
  const birthDate = new Date(birthYear, birthMonth, birthDay);
  const today = new Date();
  
  // Calculate age
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  
  // If birth month hasn't occurred yet in current year, subtract 1 from age
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  
  return age >= minAge;
}