import React, { useCallback, useEffect, useState } from 'react';
import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import { getSubscriptions } from '../services/user';
import LeftMenu from '../components/LeftMenu';
import i18n from '../utils/i18n';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { cancelSubscription } from '../services/creator';
import SubscriptionStatus from '../enums/SubscriptionStatus';
// import Suggestions from '../components/Suggestions';
// import Suggestions from '../components/Suggestions';

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [loading, setLoading] = useState(true);

  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState('');

  const navigate = useNavigate();

  const loadSubscriptions = useCallback(async () => {
    try {
      const subscriptions = await getSubscriptions();

      const filteredSubscriptions = subscriptions.filter(
        (subscription) => subscription.status !== SubscriptionStatus.NEW
      );

      setSubscriptions(filteredSubscriptions);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadSubscriptions();
  }, [loadSubscriptions]);

  const unsubscribe = async () => {
    try {
      setLoading(true);
      await cancelSubscription(selectedSubscriptionId);
      loadSubscriptions();
    } catch {
      console.log('error on unsubscribe');
    } finally {
      setLoading(false);
    }
  };

  const renderStatusLabel = (subscription: Subscription) => {
    switch (subscription.status) {
      case SubscriptionStatus.ACTIVE: {
        return (
          <div className="d-flex flex-row justify-content-center align-items-center mt-3">
            <i className="feather-check text-warning me-2 font-md"></i>
            <span className="fw-700 text-warning font-xsss">Ativa</span>
          </div>
        );
      }
      case SubscriptionStatus.CANCELLED: {
        return (
          <div className="d-flex flex-row justify-content-center align-items-center mt-3">
            <span className="fw-700 text-warning font-xsss">Cancelada</span>
          </div>
        );
      }
      case SubscriptionStatus.PROCESSING: {
        return (
          <div className="d-flex flex-row justify-content-center align-items-center mt-3">
            <i className="feather-alert-circle text-warning me-2 font-md"></i>
            <span className="fw-700 text-warning font-xsss">Processando</span>
          </div>
        );
      }
      default:
        return null;
    }
  };

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            {loading ? (
              <div
                className="align-self-center d-flex flex-column justify-content-center align-items-center"
                style={{
                  padding: 100,
                }}
              >
                <Spinner
                  animation="border"
                  role="status"
                  className="text-warning"
                />
              </div>
            ) : (
              <div className="row">
                <div className="col-xl-12">
                  <div className="card-body bg-white border-0 d-flex rounded-3">
                    <h4 className="font-xs text-black fw-600 mb-0 mt-2">
                      {i18n.t<string>('Subscriptions.title', {
                        number: subscriptions.length,
                      })}
                    </h4>
                  </div>
                  <div className="card-body bg-white">
                    <div className="mb-5 d-flex flex-wrap justify-content-center">
                      {subscriptions.length ? (
                        subscriptions.map((subscription, index) => (
                          <div
                            key={index}
                            className="p-2"
                            style={{
                              cursor: 'pointer',
                              width: 150,
                              height: 150,
                            }}
                          >
                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                              <div
                                className="card-body d-block w-100 ps-3 pe-3 pb-2 text-center"
                                onClick={() =>
                                  navigate(`/${subscription.metadata.username}`)
                                }
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                {subscription.status ===
                                  SubscriptionStatus.ACTIVE && (
                                  <div className="w-100 justify-content-end d-flex">
                                    <div
                                      className={`ms-auto pointer ${
                                        isMenuOpen ? 'show' : 'hide'
                                      }`}
                                      id="dropdownMenu4"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setIsMenuOpen(!isMenuOpen);
                                      }}
                                    >
                                      <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
                                    </div>
                                    <div
                                      className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${
                                        isMenuOpen ? 'show' : 'hide'
                                      }`}
                                      aria-labelledby="dropdownMenu4"
                                    >
                                      <div
                                        className="card-body p-0 d-flex"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();

                                          setShowUnsubscribeModal(true);
                                          setIsMenuOpen(false);
                                          setSelectedSubscriptionId(
                                            subscription.id
                                          );
                                        }}
                                      >
                                        <i className="feather-x-square text-warning me-3 font-lg"></i>
                                        <h4 className="fw-600 text-grey-900 font-xssss mt-1 me-4 pointer">
                                          Cancelar Inscrição
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
                                  <img
                                    src={
                                      subscription.metadata.profile.avatar ??
                                      'assets/images/user-100x100.png'
                                    }
                                    alt="avater"
                                    className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                                  />
                                </figure>
                                <div className="clearfix w-100"></div>
                                <h4
                                  onClick={() =>
                                    navigate(
                                      `/${subscription.metadata.username}`
                                    )
                                  }
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  className="fw-700 font-xsss mt-3 mb-0"
                                >
                                  {subscription.metadata.username}
                                </h4>
                                {renderStatusLabel(subscription)}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="align-self-center d-flex flex-column justify-content-center align-items-center p-5">
                          <img
                            src="assets/images/logo-blank.png"
                            alt="avater"
                            style={{
                              width: 50,
                              height: 50,
                            }}
                          />
                          <span className="text-grey-600 m-2 font-xss text-center">
                            {i18n.t<string>('Subscriptions.noSubscriptions')}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Modal show={showUnsubscribeModal}>
          <Modal.Header
            closeButton
            onClick={() => setShowUnsubscribeModal(false)}
            className="bg-white"
            style={{
              border: 0,
              borderRadius: 0,
              padding: 20,
              borderTopLeftRadius: 10,
              WebkitBorderTopRightRadius: 10,
            }}
          />
          <Modal.Body className="bg-white">
            <p className="font-xs text-black text-center">
              Deseja realmente cancelar a inscrição?
            </p>
          </Modal.Body>
          <Modal.Footer className="bg-white d-flex flex-row">
            <div className="form-group mb-1 flex-grow-1">
              <button
                onClick={() => setShowUnsubscribeModal(false)}
                className="form-control text-center text-white fw-600 bg-dark border-0 p-0"
              >
                Não
              </button>
            </div>
            <div className="form-group mb-1 flex-grow-1">
              <button
                onClick={() => {
                  setShowUnsubscribeModal(false);
                  unsubscribe();
                }}
                className="form-control text-center text-white fw-600 bg-warning border-0 p-0"
              >
                Confirmar
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <Appfooter />
    </>
  );
};

export default Subscriptions;
