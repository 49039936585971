import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import LeftMenu from '../components/LeftMenu';
import { Form, Spinner } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { bankingInstitutionsData } from '../utils/data';
import {
  getBankAccount,
  saveAccountBank,
  updateBankAccount,
} from '../services/creator';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { validateCNPJ, validateCPF } from '../utils/validation';

const PayoutAccount = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    isValid,
    values,
    errors,
  } = useFormik({
    initialValues: {
      documentType: 'CPF',
      document: '',
      name: '',
      pixKey: '',
      bank: '',
      id: '',
    },
    onSubmit,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      documentType: Yup.string().required('Obrigatório'),
      document: Yup.string()
        .required('Obrigatório')
        .test('is-valid-document', 'Documento inválido', function (value) {
          const { documentType } = this.parent;
          if (documentType === 'CPF') {
            return validateCPF(value || ''); // Validate CPF
          } else if (documentType === 'CNPJ') {
            return validateCNPJ(value || ''); // Validate CNPJ
          }
          return false;
        }),
      name: Yup.string().required('Obrigatório'),
      pixKey: Yup.string().required('Obrigatório'),
      bank: Yup.string().required('Obrigatório'),
    }),
  });

  useEffect(() => {
    async function fetchBankAccount() {
      try {
        setLoading(true);

        const bankAccount = await getBankAccount();

        setFieldValue('documentType', bankAccount.documentType);
        setFieldValue('document', bankAccount.document);
        setFieldValue('name', bankAccount.name);
        setFieldValue('pixKey', bankAccount.pixKey);
        setFieldValue('bank', bankAccount.bank);
        setFieldValue('id', bankAccount.id);
      } catch (error: any) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    fetchBankAccount();
  }, [setFieldValue]);

  async function onSubmit(values: BankAccount) {
    try {
      setLoading(true);

      const bankAccount: BankAccount = {
        ...values,
        document: values.document.replace(/\D/g, ''),
      };

      if (values.id) {
        await updateBankAccount(bankAccount);
        setShowConfirmationModal(true);
        return;
      }

      await saveAccountBank(bankAccount);

      setShowConfirmationModal(true);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const bankingInstitutions = useMemo(() => {
    const genders: { label: string; value: string }[] = [];

    for (const bank of bankingInstitutionsData) {
      genders.push({
        label: bank.value,
        value: bank.value,
      });
    }

    return genders;
  }, []);

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
                  <button
                    onClick={() => navigate(-1)}
                    className="d-inline-block mt-2 bg-transparent border-0"
                  >
                    <i className="ti-arrow-left font-sm text-black"></i>
                  </button>
                  <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
                    Conta de Recebimento
                  </h4>
                </div>
                <div className="p-4 d-flex justify-content-center">
                  {loading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      className="text-warning mt-3"
                    />
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group mb-3">
                        <Form className="d-flex mb-4">
                          <Form.Check
                            type="radio"
                            label="CPF"
                            name="documentType"
                            checked={values.documentType === 'CPF'}
                            onChange={() =>
                              setFieldValue('documentType', 'CPF')
                            }
                            className="me-3 font-xsss fw-600 text-grey-700"
                          />
                          <Form.Check
                            type="radio"
                            label="CNPJ"
                            name="documentType"
                            checked={values.documentType === 'CNPJ'}
                            onChange={() =>
                              setFieldValue('documentType', 'CNPJ')
                            }
                            className="font-xsss fw-600 text-grey-700"
                          />
                        </Form>
                      </div>
                      <div className="form-group mb-3">
                        {values.documentType === 'CPF' ? (
                          <InputMask
                            className="form-control font-xss"
                            mask="999.999.999-99"
                            placeholder="000.000.000-00"
                            value={values.document}
                            name="document"
                            id="document"
                            onChange={handleChange}
                          />
                        ) : (
                          <InputMask
                            className="form-control font-xss"
                            mask="99.999.999/9999-99"
                            placeholder="00.000.000/0000-00"
                            value={values.document}
                            name="document"
                            id="document"
                            onChange={handleChange}
                          />
                        )}
                        <span className="text-danger font-xsss fw-500">
                          {touched.document && errors.document ? errors.document : ''}
                        </span>
                      </div>
                      <div className="form-group mb-3">
                        {values.documentType === 'CPF' ? (
                          <>
                            <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                              Nome Completo
                            </label>
                            <input
                              type="text"
                              className="form-control font-xss"
                              value={values.name}
                              placeholder={'Nome Completo'}
                              name="name"
                              id="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </>
                        ) : (
                          <>
                            <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                              Razão Social
                            </label>
                            <input
                              type="text"
                              className="form-control font-xss"
                              value={values.name}
                              placeholder="Razão Social"
                              name="name"
                              id="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </>
                        )}
                        <span className="text-danger font-xsss fw-500">
                          {touched.name && errors.name ? errors.name : ''}
                        </span>
                      </div>
                      <div className="form-group mb-3">
                        <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                          Chave PIX
                        </label>
                        <input
                          type="text"
                          className="form-control font-xss"
                          value={values.pixKey}
                          placeholder={'Chave Pix'}
                          name="pixKey"
                          id="pixKey"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <span className="text-danger font-xsss fw-500">
                          {touched.pixKey && errors.pixKey ? errors.pixKey : ''}
                        </span>
                      </div>
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                          Instituição Bancária
                        </label>
                        <Select
                          onChange={(gender: any) => {
                            setFieldValue('bank', gender.value);
                          }}
                          className="font-xss"
                          value={{
                            label: values.bank,
                            value: values.bank,
                          }}
                          placeholder="Instituição Bancária"
                          options={bankingInstitutions}
                        />
                        <span className="text-danger font-xsss fw-500">
                          {touched.bank && errors.bank ? errors.bank : ''}
                        </span>
                      </div>
                      <p className="font-xsss text-center fw-600 mt-5">
                        Atenção: O proprietário da conta bancária precisa ser o
                        mesmo que o titular da conta na plataforma. Caso ocorra
                        alguma divergência, a solicitação de saque será
                        cancelada.
                      </p>
                      <div className="d-flex justify-content-center align-items-center mt-5">
                        <button
                          type="submit"
                          className="w-100 rounded-3 bg-current mb-2 mt-2 p-3 fw-600 fw-700 text-center font-xssss mont-font text-uppercase ls-3 text-white border-0 bg-warning"
                          disabled={!isValid}
                          style={!isValid ? { opacity: 0.5 } : { opacity: 1 }}
                        >
                          Salvar dados bancários
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showConfirmationModal}>
        <Modal.Header
          closeButton
          onClick={() => setShowConfirmationModal(false)}
          className="bg-white"
          style={{
            border: 0,
            borderRadius: 0,
            padding: 20,
            borderTopLeftRadius: 10,
            WebkitBorderTopRightRadius: 10,
          }}
        />
        <Modal.Body className="bg-white">
          <p className="font-xs text-black text-center">
            Os dados bancários foram salvos com sucesso!
          </p>
        </Modal.Body>
        <Modal.Footer className="bg-white d-flex flex-row">
          <div className="form-group mb-1 flex-grow-1">
            <button
              onClick={() => setShowConfirmationModal(false)}
              className="form-control text-center text-white fw-600 bg-warning border-0 p-0"
            >
              OK
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Appfooter />
    </>
  );
};

export default PayoutAccount;
